<template>
  <div>{{ $route.params.shortcode }}</div>
  <br>
  <div>{{ tempthing }}</div>
</template>
  
  <script>
  import { store } from '../store.js'
  export default {
    name: 'ReloadSession',
    data() {
      return {
        tempthing: "",
        store,
        combinations: {
          uuu: "a",
          uun: "b",
          uup: "c",
          uuy: "d",
          unu: "e",
          unn: "f",
          unp: "g",
          uny: "h",
          upu: "i",
          upn: "j",
          upp: "k",
          upy: "l",
          uyu: "m",
          uyn: "n",
          uyp: "o",
          uyy: "p",
          nuu: "q",
          nun: "r",
          nup: "s",
          nuy: "t",
          nnu: "u",
          nnn: "v",
          nnp: "w",
          nny: "x",
          npu: "y",
          npn: "z",
          npp: "0",
          npy: "1",
          nyu: "2",
          nyn: "3",
          nyp: "4",
          nyy: "5",
          puu: "6",
          pun: "7",
          pup: "8",
          puy: "9",
          pnu: "-",
          pnn: "_",
          pnp: "A",
          pny: "B",
          ppu: "C",
          ppn: "D",
          ppp: "E",
          ppy: "F",
          pyu: "G",
          pyn: "H",
          pyp: "I",
          pyy: "J",
          yuu: "K",
          yun: "L",
          yup: "M",
          yuy: "N",
          ynu: "O",
          ynn: "P",
          ynp: "Q",
          yny: "R",
          ypu: "S",
          ypn: "T",
          ypp: "U",
          ypy: "V",
          yyu: "W",
          yyn: "X",
          yyp: "Y",
          yyy: "Z",
        }
      }
    },
    methods: {
      decode(shortcode) {
        var codedArray = shortcode.split("")
        this.temp2 = codedArray;
        var lastAnswer = codedArray.pop()
        var answers = this.getKeysFromArray(codedArray, this.combinations).concat([lastAnswer])
        var newScores = this.generateObjectFromArray(answers)
        return newScores
      },
      getKeysFromArray(arr, obj) {
        const keysArray = [];

        while (arr.length > 0) {
          const letter = arr.shift();
          const key = Object.keys(obj).find(key => obj[key] === letter);
          if (key) {
            keysArray.push(key);
          } else {
            keysArray.push(null); // or any default value for unmatched letters
          }
        }

        return keysArray.join('').split('');
      },
      generateObjectFromArray(arr) {
        const obj = {};
        let index = 0;

        const domains = [4, 7, 9, 5]; // Number of entries in each domain

        for (let i = 0; i < domains.length; i++) {
          obj[i + 1] = {}; // Initialize each domain as an empty object

          for (let j = 1; j <= domains[i]; j++) {
            const letter = arr[index];
            let value = '';

            if (letter === 'y') {
              value = '2';
            } else if (letter === 'n') {
              value = '0';
            } else if (letter === 'p') {
              value = '1';
            }

            if (value !== '') {
              obj[i + 1][j] = value;
            }

            index++;
          }
        }

        return obj;
      }
  },
  mounted() {
      this.tempthing = this.decode(this.$route.params.shortcode)
      this.store.scores = this.decode(this.$route.params.shortcode)
      this.$router.push('/report')
    }
  }
      
</script>
